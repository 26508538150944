import { ChangeDetectorRef, Component, OnDestroy } from "@angular/core";
import { AbstractControl, FormGroup, ValidatorFn} from "@angular/forms";
import { Subject } from "rxjs";


export abstract class FormPageComponent {

	showError(form: FormGroup, key: string, error: string, groupError: boolean = false) : boolean | undefined {
		try {

			if (!groupError) {
				return (form.get(key)?.touched || form.get(key)?.dirty) && form.get(key)?.hasError(error);
			} else {

				if ((form.get(key)?.touched || form.get(key)?.dirty) && form.hasError(error)) {
					form.get(key)?.setErrors({"genericError": "genericError", ...form.get(key)?.errors})
					return true
				} else {
					form.get(key)?.setErrors(null)
					return false
				}

			}
			
		} catch (error) {
			console.error(`FormControl: '${key}' wasn't found in FormGroup: ${JSON.stringify(form.value)}`, error)
			return false;
		}
	}

	aShowError(form: FormGroup, key: string, error: string, groupError: boolean = false) : Promise<boolean | undefined> {
		return new Promise((resolve) => {
			resolve(this.showError(form, key, error, groupError))
		})
	}

	showApiError(form: FormGroup, key: string) {
		return this.showError(form, key, key, true)
	}

	getApiError(form: FormGroup, key: string) {
		return form.getError(key)
	}


	// passwordsMatchValidator(form: FormGroup) {
  //   if (form.get('password') && form.get('password_confirmation')) {
  //     return form.get('password').value === form.get('password_confirmation').value ? null : { 'mismatch': true };
  //   }
  //   return null;
  // }

	passwordsMatchValidator(): ValidatorFn {
		return (control: AbstractControl): { [key: string]: boolean } => {
			if (control.get('password') && control.get('password_confirmation')) {
				return control.get('password').value === control.get('password_confirmation').value ? null : { 'mismatch': true };
			}
			return null;
		}
  }

}