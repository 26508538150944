import {Directive, ElementRef, AfterViewInit, OnInit} from '@angular/core';

@Directive({
  selector: '[startfocussed]'
})
export class AutoFocusDirective implements OnInit {
  constructor(private el: ElementRef)
  {
  }
  ngOnInit()
  {
    this.el.nativeElement.focus();
  }
}